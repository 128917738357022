const navigationFooter = document.querySelector('.navigation--footer')
const navPointsFooter = navigationFooter.querySelectorAll('.footer--listitem a')

const getFooterState = (item) => {
    const pathnames = location.pathname.split('/').slice(1)
    const pathKey = item.getAttribute('data-name')
    const isActive = pathnames.includes(pathKey)

    if(isActive) {
        item.parentElement.classList.add('active')
    }
}

navPointsFooter.forEach(item => {
    getFooterState(item);
})


const currentYearSpan = document.querySelector('footer .footer--bottom span.currentYear')
const currentYear = new Date().getFullYear();
currentYearSpan.textContent = currentYear