import './Template/_Fragment/StageMediaContainer/StageMediaContainer.js'
import './Component/TextMedia/TextMedia.js'
import './Template/_Fragment/Header/Navigation/HeaderNavigation.js'
import './Template/_Fragment/Footer/Navigation/FooterNavigation.js'

import { stageMediaSwiper } from './Template/_Fragment/StageMediaContainer/StageMediaContainer.js'
import { mediaSwiper } from './Component/TextMedia/TextMedia.js'

document.addEventListener('Neos.NodeCreated', function(event) {
    const element = event.detail.element;

    if(element.querySelector('.stagemedia--swiper')) {
        stageMediaSwiper()
    }
    if(element.querySelector('.media--swiper')) {
        mediaSwiper()
    }
})
