export function initNeosEventItemCreated(swiper, slider) {
    document.addEventListener("Neos.NodeCreated",(event) => {
        const element = event.detail.element;
        const elementClasses = element.classList;
        console.log("item created");
        if (elementClasses.contains("swiper-slide")) {
            swiper.updateSlides();
            console.log("slider item created");
        }
    });
}

export function initNeosEventItemSelected(swiper, slider) {
    document.addEventListener("Neos.NodeSelected",(event) => {
        const element = event.detail.element;
        const elementID = element.getAttribute('data-identifier')
        const isInSwiper = slider.querySelector('[data-identifier="' + elementID + '"]')

        const getIndex = (element) => parseInt(
            (element.getAttribute("aria-label") ?? "").split(" / ")[0],10
        );

        if (!element.classList.contains("swiper-slide")) {
            return;
        }

        if (isInSwiper && element.hasAttribute("aria-label")) {
            swiper.slideTo(getIndex(element) - 1);
        } else {
            console.log("new element added slide to");

            const observer = new MutationObserver(() => {
                swiper.slideTo(getIndex(element) - 1);
            });
            observer.observe(element, { attributeFilter: ['aria-label'] });
            observer.disconnect();
        }
    });
}
