const body = document.querySelector('body')
const toggleMenu = document.querySelector('.toggle--burger')
const navigationDefault = document.querySelector('.navigation--header')
const navPoints = navigationDefault.querySelectorAll('a.navigation--listitem--link, a.subnavigation--listitem--link')


// Close Navigation
const closeNavigation = () => {
    toggleMenu.classList.remove('active')
    navigationDefault.classList.remove('active')
    body.classList.remove('no-scroll')
}

// Navigation ActiveState
const getState = (item) => {
    const pathnames = location.pathname.split('/').slice(1)
    const pathKey = item.getAttribute('data-name')
    const isActive = pathnames.includes(pathKey)

    if(isActive) {
        item.closest('.navigation--listitem').classList.add('active')
    }

    if(pathnames == '' && pathKey == 'home') {
        item.closest('.navigation--listitem').classList.add('active')
    }
}

navPoints.forEach(item => {
    getState(item);
    item.addEventListener('click', () => {
        closeNavigation()
    })
})

const adjustCurrent = () => {
    navigationDefault.querySelectorAll('.navigation--listitem--link').forEach(item => {
        getState(item)
    })
}

// Open/Close Navigation Mobile
toggleMenu.addEventListener('click', () => {
    toggleMenu.classList.toggle('active')
    navigationDefault.classList.toggle('active')
    body.classList.toggle('no-scroll')

    if(toggleMenu.classList.contains('active')) {
        closeOnScroll()
    }
})


// SubNavigation Mobile
const dropdowns = navigationDefault.querySelectorAll('.listitem--link-container .dropdown')
dropdowns.forEach(item => {
    item.addEventListener('click', () => {
        item.closest('.navigation--listitem').classList.toggle('open')

        dropdowns.forEach(otherItem => {
            if (otherItem !== item) {
                otherItem.closest('.navigation--listitem').classList.remove('open');
            }
        });

        adjustCurrent()
    })
})

// Click outside
window.addEventListener('click', event => {
    if (!document.querySelector('header').contains(event.target)) {
        closeNavigation()
        adjustCurrent()
    }
})

// Scroll
const closeOnScroll = () => {
    let isClosed = false;
    const documentStartingPosition = document.querySelector('body').getBoundingClientRect().top

    window.addEventListener('scroll', () => {
        if(isClosed || window.innerWidth < 900) {
            return;
        }

        const documentCurrentPosition = document.querySelector('body').getBoundingClientRect().top
        const difference = documentStartingPosition + documentCurrentPosition * -1

        if (difference >= 200 || difference <= -200) {
            closeNavigation();
            adjustCurrent();
            isClosed = true;
        }
    })
}

